import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";

class WhatIsGojuRyu extends React.Component {
    render() {
        return (
            <Layout>

                <h1>What is Goju Ryu?</h1>
                <p>'Okinawa Goju Ryu Karate-do' translates into English as 'Okinawan hard/soft empty-hand way'.
                    Traditional Goju Ryu is a non-sport system practised for real self defence situations, health,
                    longevity and self discovery. The biproduct of this is a complete self defence system using
                    grappling, punching, kicking, knee and elbow strikes and open-handed techniques. The emphasis is on
                    close-combat fighting, with focus on manipulation of joints and vital points, strangulation,
                    throwing techniques and ground fighting. You will also learn many important foundations such as
                    generating power, grounding, tension/relaxation and body evasion.</p>

                <p>Click here to read 'Ryukyu kenpo karate-do gaiyo', a speach by Chojun Miyagi (Goju Ryu founder) about
                    karate-do.</p>


                <h2>History of Goju Ryu Karate</h2>
                <p>Karate is a martial art that originated in the Ryukyu Islands based on Okinawan indigenous fighting
                    methods and Chinese Kenpo. It began as a common fighting system known as "ti" (or "te" which means
                    hand) among the pechin class of the Rykyuans. After trade relationships were established with the
                    Ming dynasty of China by King Satto of Chūzan in 1372, some forms of Chinese martial arts were
                    introduced to the Ryukyu Islands by the visitors from China, particularly Fujian Province. A group
                    of 36 Chinese families moved to Okinawa around 1392 for the purpose of cultural exchange, where they
                    established the community of Kumemura and shared their knowledge of a wide variety of Chinese arts
                    and sciences, including the Chinese martial arts. The political centralization of Okinawa by King
                    Shō Hashi in 1429 and the 'Policy of Banning Weapons,' enforced in Okinawa after the invasion of the
                    Shimazu clan in 1609, are also factors that furthered the development of unarmed combat techniques
                    in Okinawa.</p>

                <p>There were few formal styles of ti, but rather many practitioners with their own methods. Early
                    styles of karate are often generalized as Shuri-te, Naha-te, and Tomari-te, named after the three
                    cities from which they emerged. Each area and its teachers had particular kata, techniques, and
                    principles that distinguished their local version of ti from the others.</p>

                <p>Members of the Okinawan upper classes were sent to China regularly to study various political and
                    practical disciplines. The incorporation of empty-handed Chinese wu shuinto Okinawan martial arts
                    occurred partly because of these exchanges.</p>

                <p>Traditional karate kata bear a strong resemblance to the forms found in Fujian martial arts such as
                    Fujian White Crane, Five Ancestors, and Gangrou-quan (Hard Soft Fist; pronounced "Gojuken" in
                    Japanese). Further influence came from Southeast Asia— particularly Sumatra, Java, and Melaka. Many
                    Okinawan weapons such as the sai, tonfa, and nunchaku may have originated in and around Southeast
                    Asia.</p>

                <p>Goju Ryu was particullarly determined from Monk Fist (Luohan Quan), Crane Boxing (He Quan) and Tiger
                    Boxing (Hu Quan) as it became gradually formed by Kanryo Higaonna and Chojun Miyagi.</p>

                <p>In 1881 <strong>Higaonna Kanryo</strong> returned from China after years of instruction with Ryu Ryu
                    Ko and founded what would become Naha-te. His student <strong>Chojun Miyagi</strong> was the founder
                    of Goju Ryu. Chojun Miyagi taught such well-known karateka as <strong>An'ichi
                        Miyagi</strong> (teacher of <strong>Morio Higaonna</strong>, I.O.G.K.F Chief Instructor), Seko
                    Higa (who also trained with Higaonna), Meitoku Yagi, Miyazato Ei'ichi, and Seikichi Toguchi.</p>

            </Layout>);
    }
}

export default WhatIsGojuRyu;
